import { video_watch_path } from '../routes';

function markVideoAsWatched(element) {
  var video_slug = element.data('video');
  var url = video_watch_path({ locale: I18n.locale, slug: video_slug });
  
  var csrf_token = $('meta[name="csrf-token"]').attr("content");

  $.post({ url: url, headers: { "X-CSRF-Token": csrf_token } }, function (data) {
    toastr.success(I18n.t('video.show.watched'));
  }).fail(function(data) {
    element.data('should-watch', true);
  });

  element.data('should-watch', false);
}

$(document).on("turbo:load", () => {
  if ( $('#video-player-modal').length > 0) {
    var element = $('#video-player-modal iframe').first();
    var player = new Vimeo.Player(element);

    player.on('timeupdate', function(data) {
      if( data.percent >= 0.75 && element.data('should-watch') == true ) {
        markVideoAsWatched(element);
      }
    });

    $("#video-player-modal").on('hide.bs.modal', function () {
      player.pause();
    });
  }
});